<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading class list
  </loading-spinner>
  <div v-else>
    <div class="pb-3 d-flex justify-content-between align-items-center">
      <div class="input-group">
        <span class="input-group-text bg-white">
          <i class="fas fa-search" />
        </span>
        <input
          type="text"
          class="form-control border-start-0 ps-0 no-focus"
          :class="{ 'border-end-0': search.length > 0 }"
          placeholder="Search class by name"
          v-model="search"
        />
        <button
          class="btn bg-white border border-light"
          type="button"
          v-if="search.length > 0"
          @click="search = ''"
        >
          &times;
        </button>
      </div>
      <div class="ms-2" style="width: 10rem">
        <select class="form-select" v-model="filterStatus">
          <option :value="null">All</option>
          <option value="Published">Published</option>
          <option value="Draft">Draft</option>
        </select>
      </div>
    </div>
    <div
      class="
        pb-3
        d-flex
        justify-content-between
        align-items-center
        flex-column flex-sm-row
      "
    >
      <div>
        <span class="badge bg-dark shadow-sm py-2 fw-normal">
          Total Classes: {{ classes.length }}
        </span>
        <span class="badge bg-success shadow-sm py-2 ms-2 fw-normal">
          Published:
          {{ classes.filter((cls) => cls.status == "Published").length }}
        </span>
        <span
          class="badge bg-light border text-dark shadow-sm py-2 ms-2 fw-normal"
        >
          Draft:
          {{ classes.filter((cls) => cls.status == "Draft").length }}
        </span>
      </div>
      <button
        class="btn btn-sm btn-primary px-3 mt-2 mt-sm-0"
        @click="viewClass()"
      >
        <i class="fas fa-plus me-2" />
        Add class
      </button>
    </div>
    <div class="d-flex flex-row-reverse">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ 'active': currentPage === pageNumber }">
            <a class="page-link" href="#" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
          </li>
          <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click="nextPage">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <div
      class="
        table-responsive
        rounded
        shadow-sm
        rounded
        border border-light
        position-relative
      "
    >
      <table class="table lh-sm table-admin w-100">
        <thead>
          <tr>
            <th class="pe-0" style="width: 1%"></th>
            <th class="pe-0">Name</th>
            <th class="pe-0">Note</th>
            <th class="text-center pe-0">Students</th>
            <th class="text-center pe-0">Resources</th>
            <th class="text-center">Status</th>
            <th class="text-center ps-0">Link</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="cls in displayedData" :key="cls._id">
            <td class="pe-0" style="width: 1%">
              <img
                :src="Helper.formatMediaUrl(cls.poster)"
                style="height: 20px; width: 20px"
                class="shadow-sm rounded clickable"
                v-if="Helper.formatMediaUrl(cls.poster)"
                @click.stop="currentMedia = cls.poster"
              />
            </td>
            <td class="text-nowrap text-truncate pe-0">
              <button
                class="
                  btn btn-link
                  p-0
                  w-100
                  lh-sm
                  clickable
                  text-start text-decoration-none text-truncate
                "
                :title="cls.name"
                v-html="cls.name"
                @click="viewClass(cls)"
              ></button>
              <br />
              <div
                class="badge me-lg-2 mb-2 mb-lg-0"
                :class="`bg-${Helper.getLevelColor(cls.level)}`"
                v-if="cls.level"
              >
                <small>{{ cls.level }}</small>
              </div>
            </td>
            <td class="text-nowrap text-truncate pe-0">
              <small v-html="cls.note"></small>
            </td>
            <td class="text-center pe-0" style="width: 1%">
              <span class="badge bg-light border text-dark fw-normal p-2">
                {{ cls.dependants ? cls.dependants.length : 0 }}
              </span>
            </td>
            <td class="text-center pe-0" style="width: 1%">
              <span class="badge bg-light border text-dark fw-normal p-2">
                {{ cls.resources ? cls.resources.length : 0 }}
              </span>
            </td>
            <td
              style="width: 1%"
              class="text-nowrap text-truncate text-center ps-0"
              :class="cls.status == 'Published' ? 'text-success' : 'text-muted'"
            >
              <i
                class="fas fa-circle"
                :class="
                  cls.status == 'Published'
                    ? 'text-success'
                    : 'text-light border rounded-circle shadow-sm'
                "
              />
            </td>
            <td
              class="text-nowrap text-truncate text-center ps-0"
              style="width: 1%"
            >
              <a :href="cls.link" target="_blank" class="mx-1" v-if="cls.link">
                <img src="@/assets/icons/onpay.png" style="height: 20px" />
              </a>
            </td>
          </tr>
          <tr v-if="computedClasses.length < 1">
            <td colspan="8" class="text-center fst-italic text-muted">
              No class found
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="mt-4 d-flex flex-row-reverse">
      <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" :class="{ 'disabled': currentPage === 1 }">
            <a class="page-link" href="#" aria-label="Previous" @click="prevPage">
              <span aria-hidden="true">&laquo;</span>
              <span class="sr-only">Previous</span>
            </a>
          </li>
          <li class="page-item" v-for="pageNumber in totalPages" :key="pageNumber" :class="{ 'active': currentPage === pageNumber }">
            <a class="page-link" href="#" @click="goToPage(pageNumber)">{{ pageNumber }}</a>
          </li>
          <li class="page-item" :class="{ 'disabled': currentPage === totalPages }">
            <a class="page-link" href="#" aria-label="Next" @click="nextPage">
              <span aria-hidden="true">&raquo;</span>
              <span class="sr-only">Next</span>
            </a>
          </li>
        </ul>
      </nav>
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      @close="currentMedia = null"
    />
    <manage-class-form
      v-if="showClassModal"
      :cls="currentClass"
      @close="closeClassModal"
      @update="updateClassDetails"
      @delete="closeClassModal($event, 'delete')"
      @add="closeClassModal($event, 'add')"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";
import ManageClassForm from "@/components/Modals/ManageClassForm";

export default {
  components: { LoadingSpinner, MediaViewer, ManageClassForm },
  data() {
    return {
      isLoading: false,
      classes: [],
      search: "",
      currentMedia: null,
      currentClass: null,
      showClassModal: false,
      filterStatus: null,
      currentPage: 1,
      itemsPerPage: 10
    };
  },
  computed: {
    computedClasses() {
      const search = this.search.toLowerCase();

      let result = [...this.classes];

      if (this.filterStatus) {
        result = result.filter(
          (enquiry) => enquiry.status == this.filterStatus
        );
      }

      return result.filter((enquiry) =>
        enquiry.name.toLowerCase().includes(search)
      );
    },
    totalPages() {
      return Math.ceil(this.classes.length / this.itemsPerPage);
    },
    displayedData() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      
      if(this.search != ''){
        return this.computedClasses
      } else {
        return this.classes.slice(startIndex, endIndex);
      }
    }
  },
  methods: {
    goToPage(pageNumber) {
      if (pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
        this.search = '';
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.search = '';
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.search = '';
      }
    },
    updateClassDetails(cls) {
      if (cls) {
        this.currentClass = cls;

        let classIndex = this.classes.findIndex((w) => w._id == cls._id);

        if (classIndex > -1) {
          this.$set(this.classes, classIndex, cls);
        }
      }
    },
    viewClass(cls) {
      this.currentClass = cls;

      this.showClassModal = true;
    },
    closeClassModal(cls, type) {
      this.currentClass = null;

      if (cls) {
        if (type == "add") {
          this.classes.push(cls);
        } else {
          const classIndex = this.classes.findIndex((w) => w._id == cls._id);

          if (classIndex > -1) {
            if (type == "delete") {
              this.classes = this.classes.filter((w) => w._id != cls._id);
            } else {
              this.$set(this.classes, classIndex, cls);
            }
          }
        }
      }

      this.showClassModal = false;
    },
    getClasses() {
      this.isLoading = true;

      this.API.get("classes?_limit=-1&status_ne=Deleted")
        .then((retVal) => {
          this.classes = retVal.data;
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.getClasses();
  },
};
</script>